import './App.css';
import Nav from './Nav';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Projects from './Projects';
import Resume from './Resume';
import Home from './Home';
import MyEducation from './MyEducation';
import Volunteering from './Volunteering';

function App() {
  return (
   <Router>
      <div className="App">
       
        <Nav />
        <div className="Container">
        <Routes>
          {/* make a Home Page */}
          <Route path='/' element={<Home />} />
          <Route path='/projects' element={<Projects />} />
          {/* make a Resume Page */}
          <Route path='/resume' element={<Resume />} />
          {/* MyEducation */}
          <Route path='/education' element={<MyEducation />} />
          {/* volunteering */}
          <Route path='/volunteering' element={<Volunteering />} />

        </Routes>
        </div>
   
      </div>
      

   </Router>
  );
}

export default App;
