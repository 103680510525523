import React from 'react';
import './Home.css';

export default function Home() {
    return (
        <div className="container">

            <div className="headering2">
                <h1>Mahanoor Syed</h1>
                <h6 class="centering">Electronic and Information Engineering Graduate | Imperial College London  </h6>
            </div>
            <div className="h_gallery_item">
                <div className="container2" style={{ display: 'flex' }}>
                    <div className="skills" style={{ flex: 1 }}>

                        <div className="skills-container">
                            <div className="tiling">
                                <img src={require("./logos/python.png")} class="mr-3" alt="Python logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/c++.png")} class="mr-3" alt="C++ logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/html.png")} class="mr-3" alt="HTML logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/css.png")} class="mr-3" alt="CSS logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/javascript.png")} class="mr-3" alt="JavaScript logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/c#.png")} class="mr-3" alt="C# logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/github.png")} class="mr-3" alt="GitHub logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/jupyterNotebook.png")} class="mr-3" alt="Jupyter Notebook logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/f#.png")} class="mr-3" alt="F# logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/rasberry.png")} class="mr-3" alt="Raspberry Pi logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/ubuntu.png")} class="mr-3" alt="Ubuntu logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/flutter.png")} class="mr-3" alt="Flutter logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/ltspice.jpg")} class="mr-3" alt="LTSpice logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/solidity.png")} class="mr-3" alt="Solidity logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/julia.png")} class="mr-3" alt="Julia logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/blazor.png")} class="mr-3" alt="Blazor logo" />
                            </div>
                            <div className="tiling">
                                <img src={require("./logos/dynamo].png")} class="mr-3" alt="Dynamo logo" />
                            </div>
                            <div className='tiling'>
                                <img src={require("./logos/react.png")} class="mr-3" alt="React logo" />
                            </div>
                            <div className='tiling'>
                                <img src={require("./logos/tab.jpg")} class="mr-3" alt="Tableau logo" />
                            </div>
                            <div className='tiling'>
                                <img src={require("./logos/figma.png")} class="mr-3" alt="Figma logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="gallery_f_inner">
                <div class=" tall2 brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h3> Awards </h3>
                                {/* <h7 class="small"> More info in CV </h7> */}
                            </div>

                            <div className="tilingaward">
                                Microsoft HackTogether Hackathon - Honorable Mention for Nexus 2023
                            </div>
                            <div className="tilingaward">
                                Best Individual Research Project 2019
                            </div>
                            <div className="tilingaward">
                                Talent 2030 National Engineering Competition for Girls Finalist 2019
                            </div>
                            <div className="tilingaward">
                                Queen Mary University of London Mathematics Summer School Best Project Award 2019
                            </div>
                            <div className="tilingaward">
                                <p>1st Prize at the Houses of Parliament for the Chartered Institute of Building Challenge 2018
                                    {/* website link icon */}
                                    <div class="column">
                                        <a href="https://www.ciob.org/blog/look-back-my-presidential-year"><i class="fa fa-link"></i></a>
                                        <a href="https://constructionmanagement.co.uk/ciob-and-mykindafuture-reveal-talent-and-potential/"><i class="fa fa-link"></i></a>
                                        <a href="https://issuu.com/constructionmanager6/docs/construction_manager_june_2018">Page 46-47 </a>
                                    </div>
                                </p>
                            </div>
                            <div className="tilingaward">
                                Jack Petchey Award 2014
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div class=" brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                            <h3> Languages </h3>
                            </div>
                            <div class="skills-container">
                                <div className="tiling">
                                    English 
                                </div>
                                <div className="tiling">
                                    Urdu
                                </div>
                                <div className="tiling">
                                    Hindi
                                </div>
                                <div className="tiling">
                                    French
                                </div>
                                </div>
                        </div>
                    </div>
                </div> */}
                <div class=" wide2 tall2 brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div class="container2">
                                <div class="image-column">
                                    <img src={require("./people/tc.jpg")} class="pics" />
                                </div>
                                <div class="text-column">
                                    <p >"Mahanoor has been one of the most memorable undergraduate students at the department of
                                        Electrical and Electronic Engineering. Throughout her time with us she has shown great energy
                                        and an enquiring mind. She will always speak up and ask the questions no-one else bothers.
                                        In her role as a student representative her
                                        valuable and clear contributions have helped us to understand student needs and reach wise decisions."
                                        <h6>Dr Thomas Clarke, Principal Teaching Fellow of Technological Transformation, Imperial College London</h6>
                                    </p>
                                </div>
                            </div>
                            <div class="container2">
                                <div class="image-column">
                                    <img src={require("./people/mark.JPG")} class="pics" />
                                    <img src={require("./people/james.JPG")} class="pics" />
                                    <img src={require("./people/joshua.JPG")} class="pics" />
                                </div>
                                <div class="text-column">
                                    <p >"Mahanoor is to be congratulated on successfully completing her project. She has achieved this by integrating a custom encased monitor unit, linked to a standard smartphone to present results and act as a comprehensive user interface. The initial project brief was unusually open-ended, and a couple of design iterations were needed to refine and finalise the eventual system to achieve an optimised solution. It has been a great pleasure to work with Mahanoor over the period of this project, and we all send her our very best wishes for the future."
                                        <br></br>
                                        <h6>Dr Mark Witkowski, Honorary Research Fellow, Imperial College London</h6>
                                        <h6>Dr James Mardell, Principal Engineer and Visiting Researcher, Imperial College London</h6>
                                        <h6>Dr Joshua Brown, Research Associate in Haptics, Imperial College London</h6>
                                    </p>
                                </div>
                            </div>
                            <div class="container2">
                                <div class="image-column">
                                    <img src={require("./people/haaris.jpg")} class="pics" />
                                </div>
                                <div class="text-column">
                                    <p>
                                        "I have worked with Mahanoor many times and she is very quick to learn new
                                        programming languages, paradigms, and concepts. She has a determined work ethic
                                        and can get any job done."
                                        <h6>Muhammad Haaris Khan, EIE graduate</h6>
                                    </p>
                                </div>
                            </div>
                            <div class="container2">
                                <div class="image-column">
                                    <p> Brendon </p>
                                    {/* <img src={require("./people/brendon.jpg")} class="pics" /> */}
                                </div>
                                <div class="text-column">
                                    <p>
                                        I really enjoyed working with Mahanoor on multiple projects throughout the course. She is consistently the team member making sure everything is running smoothly and is very hardworking. Mahanoor is an outstanding engineer and I look forward to hopefully working with her next year as well.
                                        <h6>Brendon Ferra, EIE graduate</h6>
                                    </p>
                                </div>
                            </div>
                            <div class="container2">
                                <div class="image-column">
                                    <img src={require("./people/charmaine.jpg")} class="pics" />

                                </div>
                                <div class="text-column">
                                    <p>
                                        "Mahanoor is a dependable, confident, and hardworking teammate. She consistently delivers results, earning the trust and respect of their team members. Their reliability, confidence in expressing opinions, and diligent work ethic make them an importance contributor to the team's success."
                                        <h6>Charmaine Louie, EIE graduate</h6>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/*                     
                <div class="brand creative customised">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                            <h3> Hobbies </h3>
                            </div>
                            <div className="skills-container">
                                <div className="tiling">
                                    Piano
                                </div>
                                <div className="tiling">
                                    Keyboard
                                </div>
                                <div className="tiling">
                                    Badminton
                                </div>
                                <div className="tiling">
                                    Singing
                                </div>
                                <div className="tiling">
                                    Drawing/Art
                                </div>
                                <div className="tiling">
                                    Football
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  */}

            </div>
        </div>
    );
}