import React from 'react';
import { Link } from 'react-router-dom';
import './Resume.css';

export default function MyEducation() {
    return (

        <div class="container">

            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="navitem">
                    <Link class="nav-link" id="home-tab" to="/resume" role="tab" aria-controls="home" aria-selected="true">Experiences</Link>
                </li>
                <li class="navitem">
                    <Link class="nav-link" id="profile-tab" to="/education" role="tab" aria-controls="profile" aria-selected="false">Education</Link>
                </li>
                <li class="navitem">
                    <Link class="nav-link" id="profile-tab2" to="/volunteering" role="tab" aria-controls="profile" aria-selected="false">Volunteering</Link>
                </li>
            </ul>
            <div class="headering">
                <h1><span>Education</span></h1>
            </div>
            <div class="gallery_f_inner imageGallery1">

                <div class=" wide2 brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <img src={require("./company/imperial_logo.jpg")} className="mr-3" alt="Imperial College London logo" />
                                <h5 className="mt-0">MEng Electronic and Information Engineering</h5>
                                <h6>Imperial College London</h6>
                                <p>2020-2024</p>
                            </div>
                            <p>Year 4</p>
                            <ul class="list">
                                <li>Principals of Distributed Ledgers</li>
                                <li>Neuroscience for Machine Learning</li>
                                <li>Optimisation</li>
                                <li>Machine Learning for Imaging</li>
                                <li>Deep Learning</li>
                                <li>Coding Theory and Cryptography</li>
                                <li>Thesis: Hyper Localised Environmental Modelling and Planning for Air Quality</li>
                            </ul>
                            <p>Year 3</p>
                            <ul class="list">
                                <li> Maths for Signals and Systems</li>
                                <li> Digital Signal Processing</li>
                                <li>Operations Research</li>
                                <li>Machine Learning</li>
                                <li>Computer Vision</li>
                                <li>Embedded Systems</li>
                                <li>High Level Programming</li>
                                <li> Microsoft Group Project</li>
                            </ul>
                            <p>Year 2</p>
                            <ul class="list">
                                <li>Signals and Systems</li>
                                <li>Mathematics for Engineers</li>
                                <li>Instruction Architectures and Compilers</li>
                                <li>Communications</li>
                                <li>Software Systems</li>
                                <li>Discrete Mathematics </li>
                                <li>Information Processing</li>
                                <li>Control</li>
                                <li>End of Second Year Mars Rover Project</li>
                            </ul>
                            <p>Year 1</p>
                            <ul class="list">
                                <li>Mathematics for Engineers</li>
                                <li>Topics in Electrical Engineering</li>
                                <ul class="list">
                                    <li>Semiconductors</li>
                                    <li>Energy</li>
                                    <li>Communications</li>
                                    <li>Waves</li>
                                </ul>
                                <li>Analysis and Design of Circuits</li>
                                <li>Programming for Engineers</li>
                                <li>Digital Electronics and Computer Architecture</li>
                                <li>End of Year 1 Music Synthesiser Group Project</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div class="column"> */}
                <div class="brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <img src={require("./company/bma.png")} className="mr-3" alt="bma" width="110" height="110" />
                                <h5 className="mt-0">A-Levels</h5>
                                <h6>Brampton Manor Academy</h6>
                                <p>2018 - 2020</p>
                            </div>
                            <ul>
                                <li>Maths - A*</li>
                                <li>Further Maths - A*</li>
                                <li>Physics - A*</li>
                                <li>Chemistry - A</li>
                            </ul>

                        </div>
                    </div>
                    <div class="adjustedsmall"></div>
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <img src={require("./company/bma.png")} className="mr-3" alt="bma" width="110" height="110" />
                                <h5 className="mt-0">GCSE</h5>
                                <h6>Brampton Manor Academy</h6>
                                <p>2013-2018</p>
                            </div>
                            <ul class="list">
                                <li>Maths - 8</li>
                                <li>English Language - 9</li>
                                <li>English Literature - 7</li>
                                <li>Physics - 9</li>
                                <li>Chemistry - 9</li>
                                <li>Biology - 8</li>
                                <li>Computer Science - 9</li>
                                <li>Geography - 9</li>
                                <li> French - 8</li>
                                <li>Design and Technology: Resistant Materials - A</li>
                                <li>Religious Studies - 9</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
}
