import React from 'react'; // ES6 js
import './Projects.css';
import videoBg from './video.mp4';

export default function Projects() {
    const itemStyle = {
        backgroundColor: 'rgba(235, 160, 198, 0.3)',
        borderRadius: '10px',
        boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.3s ease-in-out',
        padding: '10px',
        margin: '5px',
        textAlign: 'left'
    };

    return (
        <div class="container">
            <div class="headering">
                <h1>Projects</h1>
            </div>
            <div class="gallery_f_inner">
            
                <div class="wide2 brand creative">
                        <div class="h_gallery_item">
                            <div class="g_img_item">
                                <div className="centering">
                                    <h4>Generative Adversarial Network</h4>
                                    <p>Year 4</p>
                                     <img src={require("./4thyear/gan.JPG")} alt="21" width="100%" height="100%" />
                                </div>
                                <p class="padded"> I created a GAN as part of the Deep Learning project using pytorch. 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="wide2 brand creative">
                        <div class="h_gallery_item">
                            <div class="g_img_item">
                                <div className="centering">
                                    <h4>Variational AutoEncoder</h4>
                                    <p>Year 4</p>
                                    <img src={require("./4thyear/vae.JPG")} alt="21" width="100%" height="100%" />
                                </div>
                                <p class="padded"> I created a VAE  as part of the Deep Learning coursework using pytorch. 
                            </p>
                        </div>
                    </div>
                </div>
               
                <div class="brand creative">
                        <div class="h_gallery_item">
                            <div class="g_img_item">
                                <div className="centering">
                                    <h4>Semi-supervised learning classification</h4>
                                    <p>Year 4</p>
                                </div>
                                <p class="padded"> Trained a network using SimCLR Loss function. Compared Linear Probing and finetuning on the PneunomiaMNIST dataset. 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="brand creative">
                        <div class="h_gallery_item">
                            <div class="g_img_item">
                                <div className="centering">
                                    <h4>Spiking neural network</h4>
                                    <p>Year 4</p>
                                </div>
                                <p class="padded"> Implemented a spiking neural network to reproduce results from Stable propagation of synchronous spiking in cortical neural networks by Deismann et al.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="wide2 brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Smart Contract</h4>
                                <p>Year 4</p>
                            </div>
                            <p class="padded"> I created a smart contract in solidity, which consits of a primary and secondary market.
                                 A bidding was held to test the programe. 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="wide4 brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Wireframe for Correcting Balances New Screen</h4>
                                <p>Citi Bank</p>
                            </div>
                            <div class="two-column60">
                                <div class="left-column">
                                    <img class="embeddedsimg" src={require('./citi/citicollage.jpg')} alt="1" />
                                </div>
                                <div class="right-column">
                                    <p class="padded">
                                        <h6>Project Description</h6>
                                        This wireframe extended the functionality of the portal
                                        by connecting the current and new ledger operations by adding 4 new workflows,
                                        of which one is a failsafe.
                                        <ol>
                                            <li>New Balance Move Request directs the user through a
                                                series of validation and simulation steps to allow them to create a request. 
                                            </li>
                                            <li>
                                                In Progress Page contains checkpoints which shows the status (In Progress, successful, failed, Approved
                                                or Rejected) and the status type. This is the failsafe in case the user is unable to
                                                complete the New Balance Move Request at the time.
                                            </li>
                                            <li>
                                                “Pending Requests for Approval” allows a separate checker to approve or reject
                                                the request.
                                            </li>
                                            <li>
                                                “View History” shows user all activity on page. The table structure differs
                                                slightly between the filters to only render the more significant information. E.g.:
                                                anything that has a positive result does not need a “comment” section.
                                            </li>
                                        </ol>
                                        <h6 class="padded">My Role</h6>
                                        <p class="padded">
                                            My role in the strategic ledger was to design a comprehensive wireframe of the portal, from scratch.
                                            The wireframe embodies a refined application of cohesive design principles ensuring an intuitive and user-friendly experience that precludes the need for user-training.
                                        </p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wide2 brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Neuda Net Migration</h4>
                                <p>Citi Bank</p>
                            </div>
                            <video className="customised" src={videoBg} autoplay="true" loop="true" muted="true" />
                            <p class="padded"> This project drew insights from large datasets to explore whether Net Migration
                                is a suitable indicator for the developement of a country and
                                the benefits to Citi Bank.Four Indicators were explored: Inflation, Life Expectancy, GDP Per Capita and
                                Unemployment.
                                The data was cleaned and analysed using Pandas and visualised using Tableau.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="wide2 brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>This Portfolio</h4>
                                <p>Year 3</p>
                            </div>
                            <p class="padded"> This project was made in React from scratch as part of my portfolio
                                deliverable for 3rd Year and can be viewed on a phone as well!
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div class="">

                </div> */}
                {/* <div class="">

                </div> */}
                <div class="wide4 brand creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Microsoft Group Consultancy Project Nexus</h4>
                                <p>Year 3</p>
                            </div>
                            <div class="two-column60">
                                <div class="left-column">
                                    <img class="embeddedsimg" src={require('./nexus/nexuscollage.jpg')} alt="1" />

                                </div>

                                <div class="right-column">
                                    <p class="padded">
                                        <h6>Project Description</h6>
                                        Nexus combines different Microsoft 365   applications and provides a single point of entry for users to access.
                                        <ol>
                                            <li>Dashboard displays the user's calendar, emails, and todo's.</li>
                                            <li>Calendar that presents events and provides
                                                summaries for missed online meetings.</li>
                                            <li>Overview serves as a centralised resource hub, enabling users to access
                                                and interact with their emails, teams, and calendar.</li>
                                            <li>
                                                Application tracker uses AI to read emails from companies and renders them
                                                on a dashboard. Filtering methods are available to find company-related communication/information.
                                            </li>
                                            <li>
                                                SmartScribe summarises text using Azure OpenAI and write the generated summary to their OneNote.
                                            </li>
                                            <li>
                                                A universal search bar uses Graph to search across Microsoft's suite of applications.
                                            </li>
                                        </ol>
                                        <h6 class="padded">My Role</h6>
                                        <p class="padded">I designed the calendar page and the overal UI/UX. This project was also a
                                            great oppotunity for me to work with Graph API, where I solved the issue of not all events being called from Graph.
                                        </p>
                                    </p>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
                <div class="wide2 brand manipul creative">
                    <div className="adjusted"></div>
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Air Quality Tracker</h4>
                                <p>Year 3</p>
                            </div>
                            <div class="two-column40">
                                <img className="embeddedsimg" src={require('./embedded/collage.JPG')} alt="1" />

                                <p class="padded">
                                    <h6>Project Description</h6>
                                    This project was the 1st Embedded Systems Coursework. This uses the Rasberry Pi Zero WH to design an IoT system to
                                    monitor the C02 levels around London.
                                    <p></p>
                                    <h6>My Role</h6>
                                    I did the full stack design of a website which displays the information
                                    which uses the google maps API to display the location of the sensor and the recharts library to display the data as
                                    a real-time graph. The website allows multiple devices to connect to the same account simultaneously and will dynamically add the
                                    new users to the dashboard.

                                </p>



                            </div>
                        </div>
                    </div>
                </div>
                <div class="wide2 brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Music Synthesiser</h4>
                                <p>Year 3</p>
                            </div>
                            <img src={require("./synth.jpg")} alt="21" width="100%" height="100%" />
                            <p class="padded">
                                <h6>Project Description</h6>
                                This was a 4 people group project, where we programmed an esp32 microcontroller to control a music synthesiser using C++.
                                It plays different waveformed musical notes such as sawtooth, square, triangle and sine waves.
                                The pitch and frequency can be adjusted through volume controls and the user can navigate through a menu of
                                options using the joystick.
                                <p></p>
                                <h6>My Role</h6>
                                I worked on the knob control and the different waveforms. I also worked on linking different synthesisers
                                together to create a larger range.

                            </p>
                        </div>
                    </div>
                </div>
                <div class="wide3customleft brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Issie Smart Port Ordering</h4>
                                <p>Year 3</p>
                            </div>
                            <p class="padded">
                                <h6 class="padded">Project Description</h6>
                                <p class="padded">
                                    Issie is an application that is used for digital circuit design and simulation.
                                    The aim of the project was to make Issie have smart features that solve common problems
                                    like wires crossing, wires going thorugh symbols and misalligned components. </p>

                                <h6 class="padded">My Role</h6>
                                <p class="padded">
                                    I worked on the Smart Port Ordering which ensures that no wires cross between selected components.
                                    It iterates through all the edge combinations for
                                    each pair of symbols and uses the ordering from the first clicked symbol and maps to the second symbol. In case of
                                    the same edges, it splits edge into top and bottom and reverses the ordering of the second symbol's top and bottom.
                                </p>

                            </p>

                        </div>
                    </div>
                </div>
                <div class="">
                </div>
                <div class="brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Brain Tumour Detection</h4>
                                <p> Year 3 </p>
                            </div>
                            <img src={require("./brain.JPG")} width="100%" height="100%" />
                            <h6 class="padded">Project Description</h6>
                            <p class="padded"> To create a neural network that performs image segmentation to
                                identify brain tumours. </p>
                            <h6 class="padded">My Role</h6>
                            <p class="padded"> As part of the Computer Vision coursework, I created a neural network, using the U-NET
                                architecture and Pytorch to identify brain tumours. </p>
                        </div>
                    </div>
                </div>

                <div class=" brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Neural Networks</h4>
                                <p> Year 3 </p>
                            </div>
                            <h6 class="padded">Project Description</h6>
                            <p class="padded"> Part 1: Mini neural network Library implemented in Pytorch.</p>
                            <p class="padded"> Part 2: A neural network that uses 9 parameters/inputs predicts the average house
                                prices in California. The model NN developed consists of an input layer, 2
                                hidden layers and an output layer. The regressor class builds, trains and evaluates the NN using root
                                mean squared error (RMSE) as an accuracy metric.
                            </p>
                            <h6 class="padded">My Role</h6>
                            <p class="padded">
                                I worked on the NN library and the regressor class. I implemented the forward and backward
                                propagation functions and the regressor class which builds, trains and evaluates the NN.
                            </p>
                        </div>
                    </div>
                </div>

                <div class=" brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Decision Tree</h4>
                                <p> Year 3 </p>
                            </div>
                            <img src={require("./trees.JPG")} width="100%" height="100%" />
                            <p class="padded">
                                <h6 class="padded">Project Description</h6>
                                <p class="padded">
                                    This project implements a decision tree algorithm and use it to determine one of the indoor
                                    locations based on WIFI signal strengths collected from a mobile phone.
                                    Pytorch, numpy and pandas were used. The program determines the optimal place in the data to create the
                                    decision rule that maximises information gain.
                                </p>
                                <h6 class="padded">My Role</h6>
                                <p class="padded">
                                    I worked in a group of 4 to use k-fold cross validation to parse the data and create the
                                    entropy and information gain functions.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="wide4 brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>Mars Rover</h4>
                                <p> Year 2 </p>
                            </div>
                            <div class="two-column40">
                                <img class="marsimg" src={require("./mars/websitecollage.jpg")} alt="21" width="100%" height="100%" />

                                <p class="padded">
                                    <div class="adjustedp"></div>

                                    <h6 class="padded">Project Description</h6>
                                    <p class="padded">This project involved creating a Mars Rover from scratch which will investigate a
                                        terrain of known size and map the locations of aliens and their buildings on a map.
                                    </p>
                                    <div class="adjustedp"></div>
                                    <h6 class="padded">My Role</h6>
                                    <p class="padded"> I developed the full stack of the
                                        website which displays the map and tracks locaiton of the rover.
                                        The website also allows the user to choose
                                        between autonomous or manual driving, where the manual commands are given by key/button presses. The website was created using
                                        HTML, CSS and Javascript, in React and Node.js.
                                        I was also responsible for desigining the rover's control path algorithm, which uses an obstacle avoidance algorithm to navigate
                                        and avoid any aliens/buildings. This was coded in C++.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wide3custom brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div class="centering">
                                <h4>Food Poverty Hackathon</h4>
                            </div>
                            <div class="two-column40">
                                <img class="foodhack" src={require("./foodhack.jpg")} alt="21" width="100%" height="100%" />
                                <p class="padding">
                                    <h6>Project Description</h6>
                                    <p >This project was part of the Food Poverty Hackathon, where we were tasked with
                                        creating a solution to help tackle food poverty. </p>
                                    <h6>My Role</h6>
                                    <p >
                                        To help improve communication between food banks and beneficiaries, I designed a website,
                                        in a team of 6 people, which allows food banks to post their available food and beneficiaries to request food.
                                        This also extends to schools who can request food for their students. The website was created using HTML, CSS, and Javascript, in React and Node.js.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wide2 brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>The Maze Game</h4>
                                <p> Year 2 </p>
                            </div>
                            <div class="two-column40">
                                <p class="padding">
                                    <h6>Project Description</h6>
                                    <p>
                                        This project was part of the Information Processing Module where I worked in
                                        a group pf 6 people to help create a multiplayer game. The players will connect to the game using
                                        DE10Lite-FPGA's and the scoring information will be sent to the AWS EC2 instance running in the cloud.
                                    </p>
                                    <h6>My Role</h6>
                                    <p>
                                        I created the database in the server using Amazon's DynamoDB which parses incoming messages and returns the
                                        latest top scores.
                                    </p>
                                </p>
                                <img src={require("./info/infocol.jpg")} width="100%" height="100%" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                </div>
                <div class="brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>C90 To MIPS Compiler</h4>
                                <p> Year 3 </p>
                            </div>
                            <p class="padded">
                                <h6>Project Description</h6>
                                <p>
                                    Worked in pairs to develop a C to MIPS compiler for the subset of the C language.
                                    Used bison flex, C and C++ to make and test the AST tree and implement basic C features.
                                </p>
                                <h6>My Role</h6>
                                <p>
                                    I was responsible for implementing the AST tree and the basic C features such as if statements, while loops, for loops, and
                                    function calls. I also created the test script.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="wide2 brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>MIPS CPU</h4>
                            </div>
                            <p class="padded">
                                <h6>Project Description</h6>
                                <p>
                                    I worked with 6 people to create a fully synthesisable CPU in SystemVerilog to comply with
                                    the MIPS ISA specification. This CPU can interact with any Avalon-compatible memory interface.
                                </p>
                                <h6>My Role</h6>
                                <p>
                                    Helped with the control and datapath
                                    components and also wrote test cases to check the functional correctness of the CPU.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
                <div class=" brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>LTSpice Music Synthesiser</h4>
                            </div>
                            <img src={require("./adcyr1/synthesiser.JPG")} alt="synthesiser" width="100%" height="100%" />
                            <p class="padded">
                                <h6>Project Description</h6>
                                <p>
                                    In a group of 3, I simulated an music synthesiser for an 88-key piano range in LTSpice.
                                    The synthesier has different waveforms inlucluding sine, square, triangle and sawtooth and has features
                                    like an envelope generator, ADSR (Attack, Decay, Sustain and Release) and an LFO (Low Frequency Oscillator).
                                </p>
                                <h6>My Role</h6>
                                <p>
                                    I designed the LFO and the Voltage Controlled Oscillator. The LFO is used to modulate the pitch of the note
                                    and the VCO is used to generate the different waveforms.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
                <div class=" brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>ARM CPU</h4>
                            </div>
                            <img src={require("./armcpu.png")} alt="armcpu" width="100%" height="100%" />
                            <p class="padded">
                                <h6>Project Description</h6>
                                <p>
                                    The aim of this project was to design a 32-bit ARM CPU in Issie.
                                </p>
                                <h6>My Role</h6>
                                <p>
                                    I implemented and tested the shift register in Issie and integrated it with the other
                                    control and datapath components of the CPU. The shift register bit shifts the input
                                    by a specified amount and then feeds it into the ALU.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
                <div class=" brand manipul creative">
                    <div class="h_gallery_item">
                        <div class="g_img_item">
                            <div className="centering">
                                <h4>MU0 CPU</h4>
                                <p> Year 1 </p>
                            </div>
                            <img src={require("./muo.png")} alt="armcpu" width="100%" height="100%" />
                            <p class="padded">
                                <h6>Project Description</h6>
                                <p>
                                    The aim of this project was to design a 16-bit MU0 CPU in Issie.
                                </p>
                                <h6>My Role</h6>
                                <p>
                                    Designed and Tested the MU0 CPU.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}